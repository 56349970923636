import { Application } from '@hotwired/stimulus';
import { Autocomplete } from 'stimulus-autocomplete';
import { appsignal } from '../../appsignal';
import { installErrorHandler } from '@appsignal/stimulus';

const application = Application.start();
application.register('autocomplete', Autocomplete);
installErrorHandler(appsignal, application);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

import DropdownController from './dropdown_controller';
application.register('dropdown', DropdownController);

import EatmsController from '../eatms_controller';
application.register('eatms', EatmsController);

import GalleryController from './gallery_controller';
application.register('gallery', GalleryController);

import HeadlineMarkerController from './headline_marker_controller';
application.register('headline-marker', HeadlineMarkerController);

import ScrollTopButtonController from './scroll_top_button_controller';
application.register('scroll-top-button', ScrollTopButtonController);

import TabsController from './tabs_controller';
application.register('tabs', TabsController);

import TeaserRowController from './teaser_row_controller';
application.register('teaser-row', TeaserRowController);

import ToastController from './toast_controller';
application.register('toast', ToastController);
