import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="headline-marker"
export default class extends Controller {
  connect() {
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle('is-in-view', entry.isIntersecting);
        });
      },
      {
        threshold: 1
      }
    ).observe(this.element);
  }
}
