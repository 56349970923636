// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import './channels/website';
import './controllers/website';
import 'form-request-submit-polyfill';
import { appsignal } from './appsignal';
import { hotfixTurbo } from './turbo_hotfix';

Rails.start();
ActiveStorage.start();

hotfixTurbo();
