import { Controller } from '@hotwired/stimulus';
import { useHotkeys } from 'stimulus-use';

export default class extends Controller {
  static targets = ['button', 'tabpanel'];

  connect() {
    this.index = 0;
    this.tabCount = this.buttonTargets.length;
    this.maxIndex = this.tabCount - 1;

    this.buttonTargets.forEach((button) => {
      useHotkeys(this, {
        hotkeys: {
          // ArrowLeft
          left: {
            handler: () => this.focusToTab(this.index - 1),
            options: {
              element: button
            }
          },
          // ArrowRight
          right: {
            handler: () => this.focusToTab(this.index + 1),
            options: {
              element: button
            }
          }
        }
      });
    });
  }

  onButtonClick(e) {
    const index = this.buttonTargets.indexOf(e.target);
    this.focusToTab(index);
  }

  focusToTab(index) {
    if (index < 0) {
      this.index = this.maxIndex;
    } else if (index > this.maxIndex) {
      this.index = 0;
    } else {
      this.index = index;
    }

    const activeButton = this.buttonTargets[this.index];
    activeButton.focus();
    activeButton.setAttribute('tabindex', 0);
    activeButton.setAttribute('aria-selected', true);

    const activeContent = this.tabpanelTargets[this.index];
    activeContent.classList.remove('is-hidden');

    // Inactivate other buttons/content
    const inactiveButtons = this.buttonTargets.filter((_, i) => i !== this.index);
    inactiveButtons.forEach((button) => {
      button.setAttribute('tabindex', '-1');
      button.setAttribute('aria-selected', 'false');
    });

    const inactiveContents = this.tabpanelTargets.filter((_, i) => i !== this.index);
    inactiveContents.forEach((content) => {
      content.classList.add('is-hidden');
    });
  }
}
