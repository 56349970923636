import Swiper, { Navigation } from 'swiper';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['prevButton', 'nextButton'];

  connect() {
    this.swiper = new Swiper(this.element, {
      grabCursor: true,
      modules: [Navigation],
      observer: true,
      breakpoints: {
        0: { slidesPerView: 1 },
        768: { slidesPerView: 2 }
      },

      navigation: {
        disabledClass: 'is-disabled',
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget
      }
    });
  }
}
