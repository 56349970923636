import { Controller } from '@hotwired/stimulus';
import { useClickOutside, useHotkeys } from 'stimulus-use';

export default class extends Controller {
  static targets = ['button', 'dropdown'];

  connect() {
    useClickOutside(this, { dispatchEvent: false });

    useHotkeys(this, {
      Escape: [this.closeDropdown]
    });
  }

  isOpen() {
    return this.buttonTarget.getAttribute('aria-expanded') === 'true';
  }

  openDropdown() {
    this.buttonTarget.setAttribute('aria-expanded', 'true');
    this.toggleTabIndex(true);
  }

  closeDropdown() {
    this.buttonTarget.setAttribute('aria-expanded', 'false');
    this.toggleTabIndex(false);
  }

  toggleDropdown() {
    this.isOpen() ? this.closeDropdown() : this.openDropdown();
    this.dropdownTarget.toggleAttribute('inert', !this.isOpen());
  }

  /**
   *
   * @param {boolean} isNavOpen - true if open, false if closed
   */
  toggleTabIndex(isNavOpen) {
    const dropdownInteravtiveEls = this.dropdownTarget.querySelectorAll(
      'a, select, button, input, textarea'
    );
    dropdownInteravtiveEls.forEach((el) =>
      el.setAttribute('tabindex', isNavOpen ? 0 : -1)
    );
  }

  clickOutside() {
    this.closeDropdown();
  }
}
