import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    category: String,
    pageType: String,
    product: String,
    load: Boolean
  };

  connect() {
    if (!this.loadValue) return;
    window.addEventListener(
      'CookiebotOnAccept',
      (e) => {
        if (Cookiebot.consent.marketing) {
          const script = document.createElement('script');
          const scriptSrc =
            (script.src = `https://trck.picturepeople.de/trck/etms/eatms.js?campaign_id=1&product=${
              this.productValue
            }&category=${this.categoryValue}&page_type=${
              this.pageTypeValue
            }&disable_tags=${!Cookiebot.consent.marketing}`);

          script.src = encodeURI(scriptSrc);
          document.body.append(script);
        }
      },
      false
    );
  }
}
