export const hotfixTurbo = () => {
  // For some reason redirects from within a turbo frame stopped working after a while
  // I could not reproduce why
  // This is a nasty hotfix which catches `turbo:frame-missing` events and initiates a redirect
  // Nasty because it produces a second redirect after the first one fails due to the frame-missing event.
  // Additionally this adds some logging so we can identify places where this occurs.
  // To fix this behavior actions that would produce this error can be extended with a data attribute: { data: { turbo_frame: '_top' } } or { data: { turbo: false } }.
  // In most cases { turbo_frame: '_top' } should do the trick and allow us to stay inside turbo rather then leaving turbo completlty with turbo: false.
  addEventListener('turbo:frame-missing', async (event) => {
    const {
      detail: { response, visit },
      originalTarget: {
        id: id,
        ownerDocument: { URL: requestUrl }
      }
    } = event;
    event.preventDefault();

    const span = appsignal.createSpan((span) => {
      return span.setTags({
        url: response.url,
        requestUrl: requestUrl,
        id: id
      });
    });

    Promise.all([appsignal.send(span)]);

    visit(response.url);
  });
};
