import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="scroll-top-button"
export default class extends Controller {
  connect() {
    // show after 800px of scrolling down
    window.addEventListener('scroll', () => {
      this.element.classList.toggle('is-shown', window.scrollY > 800);
    });
  }

  onClick() {
    window.scrollTo(0, 0, {
      behavior: 'smooth'
    });
  }
}
