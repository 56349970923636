import Swiper, { Navigation } from 'swiper';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['prevButton', 'nextButton'];

  connect() {
    setTimeout(() => {
      this.swiper = new Swiper(this.element, {
        slidesPerView: 'auto',
        centeredSlides: true,
        preloadImages: false,
        grabCursor: true,
        modules: [Navigation],

        navigation: {
          disabledClass: 'is-disabled',
          prevEl: this.prevButtonTarget,
          nextEl: this.nextButtonTarget
        }
      });
    }, 1000);
  }
}
