import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.element.classList.add('animated');
    this.element.classList.add('slideInRight');
  }

  connect() {
    setTimeout(() => {
      this.element.classList.remove('slideInRight');
      this.element.classList.add('fadeOutRight');
    }, 4000);

    setTimeout(() => {
      this.element.remove();
    }, 4500);
  }

  disconnect() {
    this.element.classList.remove('slideInRight');
  }

  close() {
    this.element.classList.remove('slideInRight');
    this.element.classList.add('fadeOutRight');
  }
}
